.App {
  text-align: left;
}

#BioText {
  text-align: left;
  font-family: 'Baloo Chettan 2', cursive;
  font-style: oblique;
  line-height: 1.5;
}

@media screen and (min-width: 771px) {
  #BioText {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 770px) {
  #BioText {
    font-size: 1rem;
  }
}

/* .monospace {
  font-family: 'Source Code Pro', monospace;
} */